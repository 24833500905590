import CssBaseline from '@material-ui/core/CssBaseline';
import { useContext, useEffect, useState } from 'react';
import NoPermission from '../components/assets/NoPermission';
import DashboardPage from '../components/pages/dashboard/index';
import UserContext from '../src/UserContext';
import { useRouter } from 'next/router';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function getUserType(keycloakState) {
  if (
    // keycloakState?.authenticated === true &&
    (JSON.parse(sessionStorage.getItem('userSession'))?.isAdmin ||
      JSON.parse(sessionStorage.getItem('userSession'))?.isChamberUser)
  ) {
    return 'authenticated';
  } else {
    return 'notauthenticated';
  }
}

export default function Home() {
  const { keycloakstate } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    renderCorrespondingPage();
  }, [keycloakstate, router]);

  const renderCorrespondingPage = () => {
    if (
      getUserType(keycloakstate) !== 'authenticated' &&
      process.env.publicPaths?.length > 0
    ) {
      router.push(process.env.publicPaths[0]);
    } else if (getUserType(keycloakstate) === 'authenticated') {
      router.push('/dashboard');
    } else {
      router.push('/login');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
